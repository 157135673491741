//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-92:_4100,_7968,_1544,_8308,_2872,_3764,_180,_9420;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-92')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-92', "_4100,_7968,_1544,_8308,_2872,_3764,_180,_9420");
        }
      }catch (ex) {
        console.error(ex);
      }