import shopifySparkGenericDefaults from '../shopify-spark-generic/config.js';

const wheelsFields = shopifySparkGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifySparkGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

// the client asked to add a YMM home widget to the second store and redirect to the first
// the client has two domains for both stores:
// .ca: https://youngfartsrvparts.ca/ and https://www.youngfartsautoparts.ca/
// .com: https://youngfartsrvparts.com/ and https://www.youngfartsautoparts.com/
// we need to use the same top level domain
const secondStoreWebsite = 'edmonton-rv.myshopify.com';
const isOnSecondStore = window.Shopify?.shop === secondStoreWebsite;
const getHomeRedirectUrl = (pathname) =>
  isOnSecondStore
    ? `https://youngfartsautoparts.${window.location.origin.split('.').splice(-1)[0]}${pathname}`
    : pathname;

export default {
  includes: ['shopify-spark-generic'],
  ...shopifySparkGenericDefaults,
  SearchRequestDefaults: {
    ...shopifySparkGenericDefaults.SearchRequestDefaults,
    extra: {
      ...shopifySparkGenericDefaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === '/',
    },
  },
  fitmentSearch: {
    ...shopifySparkGenericDefaults.fitmentSearch,
    doNotSaveSelectedVehicle: isOnSecondStore,
  },
  Widgets: [
    ...shopifySparkGenericDefaults.Widgets,
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '#cm-home-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 680,
      isAutoVehicleSelectionDisabled: isOnSecondStore,
      redirectUrl: getHomeRedirectUrl('/collections'),
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '#cm-home-wheels',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: getHomeRedirectUrl('/collections/wheels-rims'),
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '#cm-home-tire',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: getHomeRedirectUrl('/collections/tires-all-types'),
      selectFields: tiresFields,
    },
  ],
};
